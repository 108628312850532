import React, { useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import Backend from "../../../../Backend/Backend";
const backend = new Backend();

export default function Status(props) {
  const [active, setActive] = useState(false);

  setTimeout(() => {
    check_active();
  }, 30000);

  const check_active = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: props.id,
    };
    backend.check_status(data).then((r) => {
      if (r.error == "False") {
        setActive(r.active == 0 ? false : true);
      }
    });
  };

  return (
    <div style={{ display: "flex" }}>
      Live Status :
      <div style={{ color: active ? Dark.buy : Dark.sell, paddingLeft: 2 }}>
        {active ? "Active" : "Deactive"}
      </div>
    </div>
  );
}
